import React from "react";
import Card from "react-bootstrap/Card";
import BNBicon from "../../images/icons/BNBicon.svg";
import kycAddCartBtn from "../../images/productkyc/kycAddCartBtn.svg";

const Productselcard = (props) => {
  return (
    <Card className="productcard">
      <Card.Img variant="top" src={props.imgfeat} />
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center productcardcontent">
            <div className="d-flex flex-column">
              <span className="producttitlecard">{props.productTitle}</span>
              <span className="producttitlecardbtm">Code: {props.productCode}</span>
            </div>
            <div className="d-flex flex-column justify-content-end align-items-center">
              <br/>
              <div className="w-100 text-right producttitlecard">
                {props.productPrice} BNB <img src={BNBicon} alt="" />
              </div>
            </div>
          </div>

          <img className="ml-2" src={kycAddCartBtn} alt="add cart icon" />
        </div>
      </Card.Body>
    </Card>
  );
};

export default Productselcard;
