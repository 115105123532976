import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ScrollUpButton from "react-scroll-up-button";
import { Wizard } from 'react-use-wizard';
import Auditsd from "../images/productkyc/Auditsd.gif";
import htalkama from "../images/productkyc/htalkama.gif";
import SmartContract from "../images/productkyc/SmartContract.gif";
import Productselcard from "../components/kycform/Productselcard";
import Stepperfoot from "../components/kycform/Stepperfoot";

const Kycform = () => {
  return (
    <Layout>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="KYC Form" />
      <Container className="py-5">
        <Row>
          <Col>
            <Wizard startIndex={0}>
              <div>
                <h5 className="step1title ml-4 mb-2">HedgePay 3 Security Services</h5>

                <div className="stepperinner">
                  <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center mb-5">
                    <Productselcard
                      imgfeat={SmartContract}
                      productTitle="Smart Contract Audit"
                      productCode="HS01010"
                      productPrice="2"
                    />
                    <Productselcard
                      imgfeat={Auditsd}
                      productTitle="KYC Verification"
                      productCode="HS01020"
                      productPrice="2"
                    />
                    <Productselcard
                      imgfeat={htalkama}
                      productTitle="HedgeTalk /AMA"
                      productCode="HS01030"
                      productPrice="0.1"
                    />
                  </div>
                  <div className="my-5 d-flex justify-content-center align-items-center">
                    <p className="step1para text-center">
                      Add one or more service to your cart and select check out. <br />
                      Select view more to find out other offers
                    </p>
                  </div>
                  <div className="my-3 d-flex justify-content-center align-items-center gap-5">
                    <button className="btnsecondary">View More</button >
                    <button className="btnprimary">Check out</button >
                  </div>
                </div>
              </div>
              <div>
                <h1>this thethe page 2</h1>
              </div>
              <div>
                <h1>this thethe page 3</h1>
              </div>
            </Wizard>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default Kycform;
